    //
    //_input.scss
    //

    input[type="text"] {
        padding: 0.47rem 0.75rem;
        font-size: 0.8125rem;
        font-weight: 400;
        line-height: 1.5;
        color: var(--bs-gray-700);
        background-color: var(--bs-custom-white);
        background-clip: padding-box;
        border: 1px solid var(--bs-input-border-color);
        -webkit-appearance: none;
        appearance: none;
        border-radius: 0.25rem;
        transition: border-color 0.15s;
    }

    input[type="checkbox"] {

        display: grid;
        grid-template-columns: 1em auto;
        gap: 0.5em;
    }
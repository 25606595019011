// Lightbox
@import "~ngx-lightbox/lightbox.css";

// Select 2
@import "~@ng-select/ng-select/themes/default.theme.css";

// Date Picker
@import "~flatpickr/dist/flatpickr.css";

// LeafLet Chart
@import "leaflet/dist/leaflet.css";

// Emoji Picker
// @import '~@ctrl/ngx-emoji-mart/picker';

.bx.bx-trash-alt,
.bx.bxs-edit-alt {
  cursor: pointer;
}

.emoji-mart {
  position: absolute;
  bottom: 20px;
  left: -80px;
}

.emoji-mart-preview {
  display: none;
}

// dropdown
.dropdown-toggle {
  &:after {
    display: none;
  }
}

.modal-backdrop {
  z-index: 1002 !important;
}

.dropdown-toggle::after {
  display: none;
}

.dropzone .dz-message {
  font-size: 24px;
  min-height: 150px !important;
  border: none !important;
  background: #fff !important;
  color: #495057 !important;
}

// ----- Advance Ui --------//
// Rating
.stars {
  font-size: 30px;
  color: var(--bs-gray-600);
}

.star {
  position: relative;
  display: inline-block;
}

.star_fill {
  color: $gray-500;
}

.full {
  color: #b0c4de;
}

.half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: var(--bs-gray-600);
}

.filled {
  color: #1e90ff;
}

.bad {
  color: #deb0b0;
}

.filled.bad {
  color: #ff1e1e;
}

.halfHeart {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: red;
}

// Calendar
.flatpickr-calendar.open {
  z-index: 1061;
}

// Swich Btn
.switch.switch-small.checked>.switch-pane>span {
  color: #fff;
}

.switch.switch-small>.switch-pane>span {
  font-size: 14px;
}

// Ck Editer
.ck-editor__editable {
  min-height: 245px !important;
}

.custom-accordion {
  .accordion-list {
    padding: 0px;
    background-color: transparent;
  }
}

// select2 Css
.ng-select {
  .ng-select-container {
    background-color: $input-bg;
    border-color: $input-border-color;
    color: $input-color;

    .ng-value-container .ng-input>input {
      color: $input-color;
    }
  }

  &.ng-select-opened>.ng-select-container {
    background-color: $input-bg;
    border-color: $input-border-color;
  }
}

.ng-dropdown-panel {
  border-color: $input-border-color;
  background-color: var(--#{$prefix}custom-white);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: var(--#{$prefix}custom-white);
  color: var(--#{$prefix}body-color);
}

.ng-dropdown-panel.ng-select-bottom {
  border-top-color: $input-border-color;
}

.ng-dropdown-panel.ng-select-top {
  border-bottom-color: $input-border-color;
}

//dropzone

.dropzone>.dropzone.dz-wrapper .dz-message {
  background: var(--#{$prefix}custom-white) !important;
  color: var(--#{$prefix}body-color) !important;
}

// ck editor
.ck.ck-toolbar {
  background-color: var(--#{$prefix}light) !important;
  border: 1px solid $input-border-color !important;
}

.ck-reset_all :not(.ck-reset_all-excluded *),
.ck.ck-reset_all {
  color: var(--#{$prefix}body-color) !important;
}

.ck.ck-editor__main>.ck-editor__editable {
  background-color: var(--#{$prefix}custom-white) !important;
  color: var(--#{$prefix}body-color) !important;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  border-color: $input-border-color !important;
}

.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover,
.ck.ck-button.ck-on,
a.ck.ck-button.ck-on {
  background-color: var(--#{$prefix}light) !important;
}

.ck.ck-toolbar .ck.ck-toolbar__separator {
  background: $input-border-color !important;
}

// Dark Mode Css
[data-layout-mode="dark"] {
  .auth-logo .auth-logo-light {
    display: block;
  }

  .auth-logo .auth-logo-dark {
    display: none;
  }

  // Accordion
  .accordion {
    --bs-accordion-color: #fff;
    --bs-accordion-bg: transparent;
  }

  .accordion-button {
    color: var(--bs-body-color);
  }
}

.text-default-label {
  color: var(--default-label-color) !important;
  font-weight: bold;
}

.mt-3 {
  margin-top: 30px !important;
}

.text-align-end {
  text-align: end;
}

.text-align-center {
  text-align: center;
}

.align-self-flex-end {
  align-self: flex-end;
}

.min-h-100 {
  @media (max-width: 1800px) {
    min-height: 100vh !important;
  }

  .flex-shrink {
    flex: 0 1;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.h-100 {
  @media (min-width: 1200px) {
    min-height: 100vh;
  }
}

.flex-shrink {
  flex: 0 1;
}

.flex-shrink-100 {
  flex: 0 1 100%;
}

/* Estilo para o ng-select inválido */
ng-select.custom.is-invalid .ng-select-container {
  border-color: #f46a6a;
  /* Cor vermelha para a borda */
}

ng-select.custom.is-invalid .ng-placeholder,
ng-select.custom.is-invalid .ng-value-label {
  color: #f46a6a;
  /* Cor vermelha para o texto do placeholder e valor */
}

/* Se você quiser também adicionar uma sombra vermelha ao focar, como em alguns designs de bootstrap, pode usar o seguinte: */
ng-select.custom.is-invalid .ng-select-container.ng-select-focused {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  /* Sombra vermelha */
}



.custom-checkbox-container {
  display: flex;
  align-items: right !important;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 20px;
  cursor: pointer;
  user-select: none;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-checkbox input:checked~.checkmark {
  background-color: #2196f3;
}

.checkmark-inner {
  display: none;
  height: 16px;
  /* Tamanho da bolinha menor */
  width: 16px;
  background-color: #2196f3;
  border-radius: 50%;
  border: 2px solid white;
  /* Borda branca para a bolinha menor */
}

.custom-checkbox input:checked~.checkmark .checkmark-inner {
  display: block;
}

.checkmark {
  position: relative;
  margin-right: 5px !important;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #eee;
  border-radius: 50%;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}

small {
  color: #9696A0
}

.attach {
  display: flex;
  height: 72px;
  padding: 40px 16px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border: 2px dashed #CED4DA;
  background: #FFF;
  margin: 10px 10px 0 0;
  align-items: start;
  flex-direction: column;
}

.img-file {

  width: 91px;
  height: 68px;
  background: lightgray 50% / contain no-repeat;
  border: 3px solid #248BCB;
  border-radius: 10px;
  cursor: pointer;
}


.logo-top {

  content: var(--topbar-logo); ///url('../images/logo_top.png');
}

ng-select.is-invalid {
  border-color: red;
}

i.mdi-trash-can-outline {
  color: #F44336;
  font-size: 16px;

}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: end;
}

.align-self-start {
  align-self: start;
}

//
// _pagination.scss
//

// Pagination rounded (Custom)
.pagination-rounded {
    .ngx-pagination {

        .current {
            background-color: var(--primary-color) !important;
            text-align: center;
            line-height: 32px;
            width: 32px;
            height: 32px;
            padding: 0;
            border-radius: 30px;
        }

        li.pagination-next a{
           padding-right: 8px;

        }

        li.pagination-previous a {
            padding-left: 8px;
        }


        a {
            border-radius: 30px;
            line-height: 32px;
            width: 32px;
            height: 32px;
            text-align: center;
            padding: 0;
        }
    }
}

// 
// _card.scss
// 

.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: $box-shadow;

  // @media (min-width: 1200px) {
  //   min-height: 100vh;
  // }

  // .card-body {

  //   flex-wrap: wrap;
  //   justify-content: space-between;
  //   display: flex;
  //   flex-flow: wrap;
  //   flex: 0 1;
  // }
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;
}
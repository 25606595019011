/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */

.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

$aw-colors: (
  "_": ("default": ("border-color-default": null,
      "background-color-default": null,
      "symbol-color-default": null,
      "border-color-hover": null,
      "background-color-hover": null,
      "symbol-color-hover": null,
    ),
    "current": ("border-color-default": null,
      "background-color-default": null,
      "symbol-color-default": null,
      "border-color-hover": null,
      "background-color-hover": null,
      "symbol-color-hover": null,
    ),
  ),
);

@import "./node_modules/angular-archwizard/styles/archwizard.scss";

.custom-tooltip .tooltip-inner {
  background-color: #fff;
  font-size: 125%;
  color: black;
  border: 1px solid #d2d2d6;
  width: 200px;
}

.custom-tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #d2d2d6;
}

.custom-tooltip.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #d2d2d6;
}

.custom-tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #d2d2d6;
}

.custom-tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #d2d2d6;
}

.btn-size-filter {
  padding: 10px 10px !important;
  border-radius: 4px !important;
}

.btn-secondary {
  margin-right: 4px !important;
  text-transform: uppercase !important;
}

.btn-primary {
  margin-left: 4px !important;
  text-transform: uppercase !important;
}

.color-primary {
  color: var(--primary-color);
}

.button-gray {
  background-color: #5a5b6a !important;
  color: #ebf2ff !important;
  text-transform: uppercase !important;
}

.border-red {
  border: 1px solid red !important;
}

html {
  height: auto;
}